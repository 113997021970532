import React, {useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import background from '../../assets/img/bg_main.jpg';
import './ReviewPage.scss';
import cn from "classnames";

export const ReviewPage = () => {
    const [stars, setStars] = useState([
        { id: 1, isActive: false, isChecked: false },
        { id: 2, isActive: false, isChecked: false },
        { id: 3, isActive: false, isChecked: false },
        { id: 4, isActive: false, isChecked: false },
        { id: 5, isActive: false, isChecked: true },
    ]);
    const [feedback, setFeedback] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('+380');
    const [review, setReview] = useState('');

    const [isSubmited, setIsSubmited] = useState(false);
    const [reviewValidate, setReviewValidate] = useState(false);
    const [error, setError] = useState('');

    const token = "7845823298:AAHH7YTiIICekVZTawGoSinXPeX9LpiYHcg";
    const chatId = "-4677835321";

    const changeStars = (id, e) => {
        setStars((prevStars) =>
            prevStars.map((star, index) => ({
                ...star,
                isActive: index < id,
                isChecked: index + 1 === id,
            }))
        );

        stars.filter(item => {
            if (item.isChecked) {
                console.log(item)
            }
        });
    };

    useEffect(() => {
        changeStars(5);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const rate = stars.filter(item => item.isChecked).map(item => item.id)

        const message = `
            <b>Рейтинг:</b> ${rate} \n<b>Ім'я:</b> ${name} \n<b>Телефон:</b> ${phone} \n<b>Відгук:</b> ${review}
        `;

        try {
            const response = await fetch(
                `https://api.telegram.org/bot${token}/sendMessage`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        chat_id: chatId,
                        parse_mode: "HTML",
                        text: message,
                    }),
                }
            );

            if (response.ok) {
                setIsSubmited(true);
                setName('');
                changeStars(5);
                setPhone('+380');
                setReview('')
            } else {
                setError("Помилка надсилання повідомлення");
            }
        } catch (err) {
            setError("Щось пішло не так. Спробуйте ще раз.");
        }
    };

    if (error.length > 0) {
        return (
            <div className="home" style={{backgroundImage: `url(${background})`}}>
                <div className="reviews__header">
                    <h2 className="reviews__title_error">{error}</h2>
                </div>
            </div>
        )
    }

    if (isSubmited) {
        return (
            <div className="home" style={{backgroundImage: `url(${background})`}}>
                <div className="reviews__header">
                    <h2 className="reviews__title">Дякуємо!</h2>
                    <h3 className="reviews__subtitle">Ми отримали Ваш відгук</h3>
                </div>

                <div className="stage">
                    <div className="mariposa">
                        <div className="mariposa-turn">
                            <div className="mariposa-flutter"></div>
                        </div>
                    </div>

                    <div className="mariposa">
                        <div className="mariposa-turn">
                            <div className="mariposa-flutter"></div>
                        </div>
                    </div>

                    <div className="mariposa">
                        <div className="mariposa-turn">
                            <div className="mariposa-flutter"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const showFeedbackForm = () => {
        setFeedback((prev) => !prev)
    }


    return (
        <div className="home" style={{backgroundImage: `url(${background})`}}>

            <div className="reviews__header">
                <h2 className="reviews__title">Сподобалось у Парку Ґедзьо Бурбеля або маєте зауваження?</h2>
                <h3 className="reviews__subtitle">Будь ласка, напишіть про це нам</h3>
                </div>

            <form onSubmit={handleSubmit} className="reviews__form">

                <div className="reviews__form-rate">
                    <h3 className="reviews__subtitle">Оцініть нас:</h3>
                    <div className="reviews__form-stars">
                        {stars.map(item => (
                            <label className={cn('reviews__form-label', {
                                'reviews__form-label_active': item.isActive,
                                'reviews__form-label_clicked' : item.isChecked
                            })}
                                   key={item.id}>
                                <input
                                    type="radio"
                                    className="reviews__form-input"
                                    value={item.id}
                                    id={item.id}
                                    name="rating"
                                    checked={item.isChecked}
                                    onChange={(e) => {
                                        changeStars(item.id, e)
                                    }}
                                />
                            </label>
                        ))}
                    </div>
                </div>

                <textarea
                    name="review"
                    className="reviews__form-body"
                    value={review}
                    placeholder="Напишіть ваш відгук"
                    onChange={(e) => {
                        setReview(e.target.value)

                        if (e.target.value.length > 0) {
                            setReviewValidate(true)
                        } else {
                            setReviewValidate(false)
                        }
                    }}
                ></textarea>
                {!feedback && <div className="reviews__toggle" onClick={() => showFeedbackForm()}>Залишити дані для
                    зворотнього зв'язку</div>}
                {feedback && (
                    <div className="reviews__feedback">
                        <input
                            type="text"
                            placeholder="Ваше ім'я"
                            name="name"
                            value={name}
                            className="reviews__feedback-input"
                            onChange={(e) => setName(e.target.value)}
                        />

                        <InputMask
                            mask="+38 999 999 99 99"
                            value={phone}
                            placeholder="Ваш номер телефону"
                            name="phone"
                            className="reviews__feedback-input"
                            onChange={(e) => setPhone(e.target.value)}
                        >
                            {(inputProps) => <input {...inputProps} />}
                        </InputMask>

                    </div>
                )}
                <button
                    className="reviews__form-btn"
                    disabled={!reviewValidate}
                >
                    Надіслати
                </button>
                {!reviewValidate && <div className="reviews__form-btn-error">Відгук не може бути порожнім</div>}
            </form>
        </div>
    );
};